import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { EventFacade } from "@app/store/event/facades/event.facade";
import { Observable } from "rxjs";

@Component({
  selector: "app-show-randomization",
  templateUrl: "./show-randomization-dialog.component.html",
  styleUrls: ["./show-randomization-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowRandomizationDialogComponent implements OnInit {
  chosenVariant$: Observable<string>;
  randomizationRunning$: Observable<boolean>;

  constructor(
    private eventFacade: EventFacade,
    @Inject(MAT_DIALOG_DATA) public data: { variants: string[] },
  ) {}

  ngOnInit() {
    this.chosenVariant$ = this.eventFacade.getRandomizationChosenVariant$();
    this.randomizationRunning$ = this.eventFacade.getIsRandomizationRunning$();
  }
}
