export interface AskQuestionActivitySettings {
  moderateQuestions: boolean;
  canLike: boolean;
  canDislike: boolean;
  canComment: boolean;
  canEdit: boolean;
  canSendAnonymously: boolean;
  hideNames: boolean;
  showCommentsOnProjector: boolean;
  showStatisticsOnProjector: boolean;
  showOnlyOwnLikesDislikes: boolean;
  enableAIHelper: boolean;
  enableArgumentation: boolean;
  showProsArgumentsOnProjector: boolean;
  showConsArgumentsOnProjector: boolean
}

export const defaultAskQuestionActivitySettings: AskQuestionActivitySettings = {
  moderateQuestions: false,
  canLike: true,
  canDislike: true,
  canComment: true,
  canEdit: true,
  canSendAnonymously: true,
  hideNames: false,
  showCommentsOnProjector: false,
  showStatisticsOnProjector: false,
  showOnlyOwnLikesDislikes: false,
  enableAIHelper: false,
  enableArgumentation: false,
  showProsArgumentsOnProjector: false,
  showConsArgumentsOnProjector: false
};
