import { animate, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { EventFacade } from "@app/store/event/facades/event.facade";
import { interval, Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import _ from "underscore";

export interface ChooseRandomVariantOptions {
  maxVariantsSpinning: number;
  animationTime: number;
  colorScheme: string[];
}

export const defaultOptions: ChooseRandomVariantOptions = {
  maxVariantsSpinning: 7,
  animationTime: 200,
  colorScheme: ["#4629e2", "#a682ff", "#00c3e4", "#f5c309", "#9e9dab"],
};

@Component({
  selector: "app-choose-random-variant",
  templateUrl: "./choose-random-variant.component.html",
  styleUrls: ["./choose-random-variant.component.scss"],
  animations: [
    trigger("fadeIn", [
      transition(":enter", [
        style({ transform: "scale(1)", opacity: 0.9 }),
        animate("300ms linear", style({ transform: "scale(1.2)", opacity: 1 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseRandomVariantComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() showRandomization: boolean;
  @Input() variants: string[];
  @Input() chosenVariant: string;

  options: ChooseRandomVariantOptions = defaultOptions;
  currentIndex: number = null;
  currentVariants: { variant: string; color: string }[] = [];
  spinning: boolean;

  private intervalObs$: Observable<number>;
  private intervalObsSub: Subscription;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private ref: ChangeDetectorRef,
    private eventFacade: EventFacade,
  ) {}

  ngOnInit() {
    this.eventFacade
      .getIsRandomizationRunning$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isRunning) => {
        if (!isRunning) {
          this.stopSpinning();
          this.ref.detectChanges();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showRandomization) {
      if (this.showRandomization && this.variants?.length) {
        this.startSpinning();
      }
    }
    if (this.variants) {
      if (!!this.variants && this.variants?.length && this.showRandomization) {
        this.startSpinning();
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  private startSpinning(): void {
    this.chosenVariant = null;
    this.spinning = true;
    this.intervalObs$ = interval(this.options.animationTime).pipe(
      takeUntil(this.destroyed$),
    );

    this.intervalObsSub = this.intervalObs$.subscribe((intervalCount) => {
      if (!this.variants) {
        return;
      }
      const { length } = this.variants;
      const currentIndex = intervalCount % length;
      this.currentIndex = currentIndex;

      this.currentVariants.unshift({
        variant: this.variants[Math.floor(Math.random() * length)],
        color: this.randomColor(),
      });

      if (this.currentVariants.length > this.options.maxVariantsSpinning) {
        this.currentVariants.pop();
      }

      this.ref.detectChanges();
    });
  }

  private stopSpinning(): void {
    this.spinning = false;
    this.intervalObs$ = null;
    this.currentIndex = null;
    this.currentVariants = [];
    if (this.intervalObsSub) {
      this.intervalObsSub.unsubscribe();
    }
  }

  private randomColor(): string {
    const { colorScheme } = this.options;
    return colorScheme[_.random(0, colorScheme.length - 1)];
  }
}
